import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuList: [],//角色权限目录/菜单/按钮
    activeMenuId: '',//当前选中目录id
    secondLevelMenuList: [],//当前选中目录的菜单

    beforeUploadLoading: null,//上传文件前的loading动画

    pigImgUrlList: [],//全局查看图片的list
    pigImgUrl: '',//全局查看图片的单张
    uploadImgUrl: '',//上传图片接口地址
    baseUrl: '',
    // 通用的日期时间快捷方式选择
    pickerOptions: {
      shortcuts: [

        {
          text: "当天",
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date(new Date().toLocaleDateString()).getTime());
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    },
    // 操作日志类型
    eventNameAllList: [
      { name: "新增" },
      { name: "修改" },
      { name: "删除" },
    ],
    // 客户类型
    customerTypeList: [
    ],
    // 客户状态
    cooperationStatusList: [],
    // 客户星级
    customerStarList: [
    ],
    // 沟通方式
    wayCommunicationList: [],
    // 跟进结果
    followUpResultsList: [],
    // 产品线
    productLineList: [],
    // 合同类型
    contractTypeList: [],
    // 盖章类型
    sealTypeList: [],
    // 文件类别
    fileTypeList: [],
    // 免费样品总价
    totalPriceList:[],
    // 上传文件的header对象
    uploadHeaders: {}
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
