<template>
  <div class="currencyTagNavigation">
    <div class="tagNavigation">
      <div
        class="item"
        v-for="(item, index) in tagNavigation"
        :class="{ active: $route.name && $route.name.indexOf(item.name) != -1 }"
        :key="index"
      >
        <div @click="goPage(item.name)">
          {{ item.title }}
        </div>
        <span @click="closeTag(index)">
          <i class="el-icon-close"></i>
        </span>
      </div>
    </div>
    <el-dropdown @command="closeTags" :show-timeout="0" :hide-timeout="0">
      <img src="@/assets/images/tagNavigation/moreIcon.png" class="moreIcon" />
      <el-dropdown-menu slot="dropdown" style="margin-top: -15px !important">
        <el-dropdown-item command="other">关闭其他</el-dropdown-item>
        <el-dropdown-item command="all">关闭所有</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagNavigation: [],
    };
  },
  methods: {
    /**
     * 根据name跳转路由
     */
    goPage(name) {
      if (name != this.$route.name) this.$router.replace({ name });
    },
    /**
     * 关闭单个导航，传入数组下标
     * 删除后如果当前下标还有导航，跳转到该导航
     * 删除后如果当前下标没有导航，下标减一再进行跳转
     * 只剩下一个导航时不允许删除
     * @param {*} index
     */
    closeTag(index) {
      if (this.tagNavigation.length > 1) {
        let list = this.tagNavigation;
        const delItem = list.splice(index, 1)[0];
        const item = list[index] ? list[index] : list[index - 1];
        this.tagNavigation = list;
        delItem.path === this.$route.fullPath && this.$router.replace(item.path);
      }
    },

    /**
     * 设置tags
     */
    setTags(route) {
      let list = this.tagNavigation;
      const isExist = list.some((item) => {
        return item.name === route.name;
      });
      if (!isExist) {
        if (list.length >= 9) list.shift();
        list.push({
          title: route.meta.title,
          path: route.fullPath,
          name: route.name,
        });
        this.tagNavigation = list;
      }
    },
    closeTags() {
      this.tagNavigation = [];
      if (this.$route.meta.title) this.setTags(this.$route);
    },
  },
  watch: {
    // 监听路由变化，有title记录
    $route(newValue) {
      if (newValue.meta && newValue.meta.title) {
        this.setTags(newValue);
      }
    },
    // 监听tagNavigation数组变化，重新缓存
    tagNavigation() {
      localStorage.setItem("tagNavigation", JSON.stringify(this.tagNavigation));
    },
  },
  created() {
    // 组件加载时设置当前路由为tag
    if (localStorage.getItem("tagNavigation"))
      this.tagNavigation = JSON.parse(localStorage.getItem("tagNavigation"));
    if (this.$route.meta.title) this.setTags(this.$route);
  },
};
</script>
<style lang="less">
.currencyTagNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  .tagNavigation {
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 10px;
    padding-right: 20px;
    width: ~"calc(100vw - 250px)";
    overflow-x: auto;
    .item {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 5px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      border: 1px solid @mainBackgroundColor;
      margin-right: 10px;
      cursor: pointer;
      color: @mainBackgroundColor;
      opacity: 0.5;
      white-space: nowrap;
    }
    .item.active,
    .item:hover {
      color: #fff;
      background-color: @mainBackgroundColor;
      opacity: 1;
    }
  }
}
</style>
