export default [
    /**
     * 订单管理 start
     */

    // {
    //     path: "/OrderAdmin/order",
    //     name: "OrderAdminOrder",
    //     component: () =>
    //         import("@/views/OrderAdmin/order.vue"),
    //     meta: {
    //         title: '订单列表'
    //     },
    // },
    // {
    //     path: "/OrderAdmin/logistics",
    //     name: "OrderAdminLogistics",
    //     component: () =>
    //         import("@/views/OrderAdmin/logistics.vue"),
    //     meta: {
    //         title: '物流订单'
    //     },
    // },

    /**
     * 订单管理 end
     */
]