import Vue from 'vue'
// 引入axios 并配置axios和qs
import axios from "axios";
Vue.prototype.$http = axios;
import qs from "qs";
Vue.prototype.$qs = qs;
/* 配置接口基础api地址 */
if (location.port || location.host.indexOf("test.") != -1) {
  axios.defaults.baseURL = "http://192.168.1.41:9901/"
  axios.defaults.baseURL = "http://192.168.1.98:9901/"
  axios.defaults.baseURL = "http://api.genefirst.com.cn"
  // axios.defaults.baseURL = "https://testcrmapi.ardentbiomed.com.cn/"
  // axios.defaults.baseURL = "https://crmapi.ardentbiomed.com.cn"
} else
  axios.defaults.baseURL = "http://api.genefirst.com.cn/"
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 判断有登录信息的情况下，给所有接口拼接操作人相关信息
    if (localStorage.getItem("loginInfoGsCrm")) {
      config.headers = {
        'access-token': localStorage.getItem('token')
      }
    }
    if (config.qs == "yes")
      config.data = qs.stringify(config.data);
    return config;
  },
  err => {
    // 对请求错误做些什么
    Vue.prototype.$VueMessage({
      type: "warning",
      message: '操作失败，请联系管理员'
    });
  }
);
let contentType = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"]
// 响应拦截器
axios.interceptors.response.use(
  res => {
    // 对响应数据做些什么
    let data = res.data;
    if (data.code == 200 || data.size || contentType.includes(res.headers['content-type'])) {
      return data;
    } else {
      if (data.data == 'no-token' || data.msg.indexOf('Token') != -1) {
        location.hash = 'Login'
        localStorage.clear()
        location.reload()
      }
      Vue.prototype.$VueMessage({
        type: "warning",
        message: data.msg || '操作失败，请联系管理员'
      });
    }
    return data;
  },
  () => {
    Vue.prototype.$VueMessage({
      type: "warning",
      message: '操作失败，请联系管理员'
    });
    return;
  }
);
