import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import model1 from "./model1.js";
import model2 from "./model2.js";

const router = new VueRouter({
  routes: [
    ...model1,
    ...model2,
  ]
})

export default router
