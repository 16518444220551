<template>
  <div class="currencyHeader">
    <div class="none"></div>
    <!-- 用户名下拉菜单 -->
    <el-dropdown trigger="click" @command="handleCommand">
      <div class="userInfo">
        <img :src="loginInfo.avatar" class="headImg" v-if="loginInfo.avatar" />
        <div class="headImgBox" v-else>
          <img src="@/assets/images/header/defaultHeadPortrait.png" alt="" />
        </div>
        <div class="center">
          <div class="name">{{ loginInfo.name }}</div>
          <div class="position">{{ loginInfo.roleName }}</div>
        </div>
        <img src="@/assets/images/header/arrowBottomIcon.png" class="arrow" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="editPassWord" v-if="isAuth('sys:user:password')">修改密码</el-dropdown-item>
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 修改密码弹窗 -->
    <CurrencyEditPassword ref="CurrencyEditPassword"></CurrencyEditPassword>
  </div>
</template>
<script>
import CurrencyEditPassword from "./currencyEditPassword.vue";
export default {
  components: { CurrencyEditPassword },
  data() {
    return {
      loginInfo: {},
    };
  },
  mounted() {
    if (localStorage.getItem("loginInfoGsCrm")) {
      // try
      {
        let data = JSON.parse(localStorage.getItem("loginInfoGsCrm"));
        if (data.sysRoleList && data.sysRoleList.length > 0)
          data.roleName = data.sysRoleList[0].roleName;
        this.loginInfo = data;
      }
      // catch (e) {
      //   this.$router.replace({ name: "LoginStaff" });
      // }
    }
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "logout")
        this.$confirm("确定退出登录吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$logout();
          })
          .catch(() => {
            this.$VueMessage({
              message: "取消操作",
            });
          });
      else if (command == "editPassWord")
        this.$refs.CurrencyEditPassword.init();
    },
  },
};
</script>
<style lang="less">
.currencyHeader {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 16px;
  background-color: @secondBackgroundColor;
  color: #fff;

  .none {
    flex: 1;
  }

  .userInfo {
    display: flex;
    align-items: center;
    padding-right: 20px;
    margin-left: 60px;
    cursor: pointer;
    color: #fff;

    .headImg {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 10px;
    }

    .headImgBox {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      background-color: #fff;
      border-radius: 100%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }

    .center {
      .position {
        font-size: 12px;
      }
    }

    .arrow {
      width: 18px;
      margin-left: 10px;
    }
  }
}</style>
