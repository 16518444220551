<template>
  <div class="currencyMenu">
    <el-menu class="sidebar-el-menu" :collapse="$store.state.isCollapse" background-color="#511471" unique-opened
      :default-active="$route.name">
      <template v-for="item in $store.state.menuList">
        <template v-if="item.list.length > 0">
          <el-submenu :index="item.menuId + ''" :key="item.menuId">
            <template slot="title">
              <img class="icon" v-if="item.icon" :src="item.icon" />
              <span slot="title">{{ item.name }}</span>
            </template>
            <template v-for="subItem in item.list">
              <a :href="subItem.url" v-if="subItem.url.indexOf('http') != -1" target="_blank">{{ subItem.name }}</a>
              <el-menu-item v-else :index="subItem.url" :key="subItem.menuId"
                :class="{ 'is-active': $route.path.indexOf(subItem.url) != -1 }" @click.native="goPage(subItem.url)">
                <span slot="title">{{ subItem.name }}</span>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-submenu :index="item.url" :key="item.menuId">
            <template slot="title">
              <span slot="title">{{ item.name }}</span>
            </template>
          </el-submenu>
        </template>
      </template>
    </el-menu>
  </div>
</template>
<script>
export default {
  methods: {
    goPage(url) {
      if (url != this.$route.name) this.$router.replace({ name: url });
    },
  },
};
</script>
<style lang="less">
.currencyMenu {
  background-color: @secondBackgroundColor;
  font-size: 16px;
  line-height: 40px;
  height: ~"calc(100vh - 120px)";
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 10px;

  .el-menu {
    border-right: none;
  }

  .el-submenu {
    background-color: #511471 !important;
    text-align: left;

    .el-submenu__icon-arrow {
      transform: translateY(-50%);
      margin-top: -4px !important;
      height: 10px;
    }

    .el-submenu__icon-arrow::before {
      content: "";
      background: url(../assets/images/header/arrowBottomIcon.png) no-repeat;
      background-size: 14px;
      width: 14px;
      height: 10px;
      display: inline-block;
    }

    a {
      padding: 0 45px;
      display: block;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #c2c7db;
    }

    .el-submenu__title:hover {
      background-color: #ee893a !important;
    }

    .el-submenu__title {
      height: 40px !important;
      line-height: 40px !important;
      color: #fff !important;
      background-color: #511471 !important;

      span {
        font-size: 14px !important;
      }

      .el-submenu__icon-arrow {
        font-size: 18px !important;
        color: #fff !important;
      }
    }

    .el-menu-item:hover,
    a:hover {
      background-color: #ee893a !important;
    }

    .el-menu-item {
      color: #fff !important;
      background-color: #511471 !important;
      font-size: 14px !important;
      height: 50px;
      line-height: 50px;
    }

    .el-menu-item.is-active {
      background-color: #ee893a !important;
      color: #fff !important;
    }

    img.icon {
      width: 25px;
      margin-right: 10px;
    }
  }
}
</style>
