export default [
    {
        path: '/Login',
        name: 'Login',
        component: () =>
            import("@/views/Login/index.vue"), meta: {
                isAppMain: true
            }
    },
    {
        path: '/NewsClass',
        name: 'NewsClass',
        component: () =>
            import("@/views/News/NewsClass.vue"),
        meta: {
            title: '新闻分类'
        }

    },
    {
        path: '/NewsList',
        name: 'NewsList',
        component: () =>
            import("@/views/News/NewsList.vue"),
        meta: {
            title: '新闻列表'
        }

    },
    {
        path: '/NewsListAddOrEdit',
        name: 'NewsListAddOrEdit',
        component: () =>
            import("@/views/News/NewsList-add-or-edit.vue"),
        meta: {
            title: '新闻编辑'
        }

    },
    /**
     *  系统管理 start
     */
    {
        path: '/SystemAdmin/menu',
        name: 'SystemAdminMenu',
        component: () =>
            import("@/views/SystemAdmin/menu.vue"),
        meta: {
            title: '菜单列表'
        }
    },
    {
        path: '/SystemAdmin/role',
        name: 'SystemAdminRole',
        component: () =>
            import("@/views/SystemAdmin/role.vue"),
        meta: {
            title: '角色列表'
        }
    },
    {
        path: '/SystemAdmin/user',
        name: 'SystemAdminUser',
        component: () =>
            import("@/views/SystemAdmin/user.vue"),
        meta: {
            title: '用户列表'
        }
    },

    {
        path: '/SystemAdmin/Banner',
        name: 'SystemAdminBanner',
        component: () =>
            import("@/views/SystemAdmin/Banner.vue"),
        meta: {
            title: 'banner图列表'
        }
    },
    {
        path: '/SystemAdmin/redis',
        name: 'SystemAdminRedis',
        component: () =>
            import("@/views/SystemAdmin/redis.vue"),
        meta: {
            title: 'redis缓存'
        }
    },
    {
        path: '/SystemAdmin/VerificationCodeRecord',
        name: 'SystemAdminVerificationCodeRecord',
        component: () =>
            import("@/views/SystemAdmin/VerificationCodeRecord.vue"),
        meta: {
            title: '验证码记录'
        }
    },


]