export default {
    install(Vue) {
        Vue.prototype.isAuth = function (key) {
            return JSON.parse(localStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
        }
        Vue.prototype.getAuthority = function (arr = []) {
            let authority = localStorage.getItem('authority') || 1
            return arr.includes(Number(authority))
        }

        /**
         * 请求参数过滤，查询全部的时候修改为空字符串
         */
        Vue.prototype.getParameterProcessing = function (data) {
            data = JSON.parse(JSON.stringify(data))
            for (let key in data) {
                if (data[key] == '全部') data[key] = ''
            }
            return data
        }
        /**
         * post page请求参数处理
         */
        Vue.prototype.postParameterProcessing = function (data) {
            let allConditions = ['allEq', 'eq', 'ge', 'in', 'isNotNull', 'isNull', 'le', 'like', 'ne', 'orderBy', 'select', 'set']
            let object = {}
            for (const key in data) {
                if (allConditions.includes(key)) {
                    object[key] = []
                    data[key].forEach(item => {
                        // if (item[2]) {
                        object[key].push({
                            "fieldDataType": item[0],
                            "fieldName": item[1],
                            "fieldValue": item[2]
                        })
                        // }

                    });
                } else object[key] = data[key]
            }
            return object;
        }
        /**
         * minio根据图片名称删除
         */
        Vue.prototype.$deleteByNameFile = function (name) {
            this.$http.delete(`/minio/deleteByName?name=${name}`)
        }
        /**
         *  统一的上传图片前的处理 
         */
        Vue.prototype.$beforeUploadImage = function (file) {
            if (file.type && file.type.indexOf("image") != -1) {
                this.$store.state.beforeUploadLoading = this.$loading({
                    lock: true,
                    text: "上传中，请稍等......",
                    spinner: "el-icon-loading",
                });
            } else {
                this.$VueMessage({
                    message: "请上传图片格式的文件",
                    type: "warning",
                });
                return false;
            }
        }
        /**
         *  统一的上传PDF前的处理 
         */
        Vue.prototype.$beforeUploadPdf = function (file) {
            if (file.type && file.type.indexOf("pdf") != -1) {
                this.$store.state.beforeUploadLoading = this.$loading({
                    lock: true,
                    text: "上传中，请稍等......",
                    spinner: "el-icon-loading",
                });
            } else {
                this.$VueMessage({
                    message: "请上传PDF格式的文件",
                    type: "warning",
                });
                return false;
            }
        }
        /**
         * 上传图片或PDF格式判断
         */
        Vue.prototype.$beforeUploadPdfImg = function (file) {
            let testsmg = file.name.substring(file.name.lastIndexOf('.') + 1);
            const extension = testsmg === 'pdf'
            if (file.type && file.type.indexOf("image") != -1 || extension) {
                this.$store.state.beforeUploadLoading = this.$loading({
                    lock: true,
                    text: "上传中，请稍等......",
                    spinner: "el-icon-loading",
                });
            } else {
                this.$VueMessage({
                    message: "请上传PDF/图片格式的文件",
                    type: "warning",
                });
                return false;
            }
        }
        Vue.prototype.$beforeUploadVaried = function (file) {
            let testsmg = file.name.substring(file.name.lastIndexOf('.') + 1);
            const extension = testsmg === 'pdf' || testsmg === 'doc' || testsmg === 'docx'
            if (extension) {
                this.$store.state.beforeUploadLoading = this.$loading({
                    lock: true,
                    text: "上传中，请稍等......",
                    spinner: "el-icon-loading",
                });
            } else {
                this.$VueMessage({
                    message: "请上传word或者PDF格式的文件",
                    type: "warning",
                });
                return false;
            }
        }
        Vue.prototype.$beforeUploadVaried = function (file) {
            let testsmg = file.name.substring(file.name.lastIndexOf('.') + 1);
            const extension = testsmg === 'pdf' || testsmg === 'doc' || testsmg === 'docx'
            if (extension) {
                this.$store.state.beforeUploadLoading = this.$loading({
                    lock: true,
                    text: "上传中，请稍等......",
                    spinner: "el-icon-loading",
                });
            } else {
                this.$VueMessage({
                    message: "请上传word或者PDF格式的文件",
                    type: "warning",
                });
                return false;
            }
        }
        /**
         *  统一的上传Word前的处理 
         */
        Vue.prototype.$beforeUploadWord = function (file) {
            if (file.type && file.type.indexOf("word") != -1) {
                this.$store.state.beforeUploadLoading = this.$loading({
                    lock: true,
                    text: "上传中，请稍等......",
                    spinner: "el-icon-loading",
                });
            } else {
                this.$VueMessage({
                    message: "请上传word格式的文件",
                    type: "warning",
                });
                return false;
            }
        }

        /**
         *  统一的上传错误处理
         */
        Vue.prototype.$uploadError = function () {
            this.$store.state.beforeUploadLoading.close();
            this.$VueMessage({
                message: "上传失败，请重试或联系管理员",
                type: "warning",
            });
        }

        /**
         *  统一处理全局查看图片 
         */
        Vue.prototype.$VueSeePigImg = function (url, urlList) {
            this.$store.state.pigImgUrl = url;
            this.$store.state.pigImgUrlList = urlList || [url];
            setTimeout(() => {
                document.getElementById("imagePreview").click();
            }, 20);
        }
        /**
         *  统一处理全局提示，避免出现太多提示框，限制最多三个
         */
        Vue.prototype.$VueMessage = function (data) {
            if (document.getElementsByClassName("el-message").length < 3) {
                if (!data.message) data.message = '操作失败，请联系管理员'
                this.$message(data);
            }
        }
        /**
         * 过滤日期时间格式
         * @param {Number} time 时间戳
         * @param {String} format 日期格式限制
         * @returns 返回格式化后的日期时间格式
         */
        Vue.prototype.dateTimeFormat = function (time, format) {
            if (time) {
                let timeStr = ''
                /* 有些时间戳是10位数，处理成13位数 */
                if (String(time).length == 10) {
                    time = time + '000'
                    time = Number(time)
                }
                if (String(time).indexOf('-') != -1) {
                    time = String(time).replace(/-/g, '/')
                }
                let value = new Date(time);
                let year = value.getFullYear();
                let month = value.getMonth() + 1
                if (month < 10)
                    month = "0" + month
                let day = value.getDate();
                if (day < 10)
                    day = "0" + day
                let hour = value.getHours();
                if (hour < 10)
                    hour = "0" + hour
                let minutes = value.getMinutes();
                if (minutes < 10)
                    minutes = "0" + minutes
                let seconds = value.getSeconds();
                if (seconds < 10)
                    seconds = "0" + seconds
                if (format == 'NYR')
                    timeStr = `${year}-${month}-${day}`
                else if (format == 'NY')
                    timeStr = `${year}-${month}`
                else if (format == 'YR')
                    timeStr = `${month}-${day}`
                else if (format == 'NYRSF')
                    timeStr = `${year}-${month}-${day} ${hour}:${minutes}`
                else timeStr = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
                return timeStr;
            } else return ''
        }
        /**
         * 根据用户信息查询菜单
         */
        Vue.prototype.getMenu = function (isJump) {
            let menuLoading = this.$loading({
                lock: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let loginInfo = JSON.parse(localStorage.getItem("loginInfoGsCrm"))
            let url = `/sys-menu/nav?userId=${loginInfo.userId}`;
            if (loginInfo.roleId)
                //不存在用户id，用角色id查询权限
                url = `/sys-menu/navRoleId?roleId=${loginInfo.roleId}`;
            this.$http
                .get(url)
                .then((res) => {
                    menuLoading.close()
                    if (res.code == 200) {
                        let data = res.data;
                        if (data && data.length > 0) {
                            this.$store.state.menuList = data;
                            let list = [];
                            this.getRoleMenuList(data, list);
                            localStorage.setItem("permissions", JSON.stringify(list));
                        } else {
                            this.$VueMessage({
                                message: "该账户暂无权限登录，请联系管理员",
                                type: "error",
                            });
                            localStorage.removeItem("loginInfoGsCrm")
                            if (this.$route.name != localStorage.getItem("loginPageUrl")) this.$router.replace({ name: localStorage.getItem("loginPageUrl") })
                        }
                    }
                })
        }

        /**
         * 递归 获取授权菜单
         */
        Vue.prototype.getRoleMenuList = function (data, list) {
            data.forEach((item) => {
                if (item.list.length > 0) {
                    this.getRoleMenuList(item.list, list);
                } else if (item.perms) list.push(item.perms);
            });
        },
            /**
             * 获取登录用户角色名称
             */
            Vue.prototype.getUserRoleName = function () {
                let loginInfo = JSON.parse(localStorage.getItem("loginInfoGsCrm"))
                let roleName = ''
                if (
                    loginInfo.sysRoleList &&
                    loginInfo.sysRoleList.length > 0 &&
                    loginInfo.sysRoleList[0].roleName) {
                    roleName = loginInfo.sysRoleList[0].roleName;
                } else roleName = loginInfo.roleName;
                return roleName
            }
        /**
         * 获取登录用户Id
         */
        Vue.prototype.getUserId = function () {
            let loginInfo = JSON.parse(localStorage.getItem("loginInfoGsCrm"))
            let userId = ''
            if (loginInfo.id) {
                userId = loginInfo.id;
            } else userId = loginInfo.userId;
            return userId
        }
        /**
         * 获取登录用户名称
         */
        Vue.prototype.getUserName = function () {
            let loginInfo = JSON.parse(localStorage.getItem("loginInfoGsCrm"))
            let userName = ''
            userName = loginInfo.name;
            return userName
        }
        /**
         * 全局退出登录的接口
         */
        Vue.prototype.$logout = function () {
            this.$http.post(`sys-user/logOut`).then(res => {
                this.$router.replace({
                    name: localStorage.getItem('loginPageUrl')
                    // || 'LoginStaff'
                });
                localStorage.clear()
            })
        }

        /* 查询所有省份 */
        Vue.prototype.getProvince = function (node, resolve) {
            this.$http.get(`sys-province/getList`).then((res) => {
                let data = res.data;
                let list = [];
                for (let i = 0; i < data.length; i++) {
                    let item = data[i];
                    list.push({
                        uid: item.id,
                        value: item.name,
                        label: item.name,
                        name: item.id,
                        leaf: node.level >= 2,
                    });
                }
                resolve(list);
            });
        }
        /* 根据省份查询所有市 */
        Vue.prototype.getCity = function (node, resolve) {
            this.$http
                .get(`sys-city/getByProvinceId/${node.data.uid}`)
                .then((res) => {
                    let data = res.data;
                    let list = [];
                    data.forEach((item) => {
                        list.push({
                            uid: item.id,
                            value: item.name,
                            label: item.name,
                            name: item.name,
                            leaf: node.level >= 2,
                        });
                    });
                    resolve(list);
                });
        }
        /* 根据市查询所有区/县/乡 */
        Vue.prototype.getArea = function (node, resolve) {
            this.$http.get(`sys-area/getByCityId/${node.data.uid}`).then((res) => {
                let data = res.data;
                let list = [];
                data.forEach((item) => {
                    list.push({
                        uid: item.id,
                        value: item.name,
                        label: item.name,
                        name: item.name,
                        leaf: node.level >= 2,
                    });
                });
                resolve(list);
            });
        }

        /**
         * 操作日志的统一数据处理
         */
        Vue.prototype.operationLogDataHandle = function (list) {
            list.forEach((item) => {
                if (item.eventName == "修改") {
                    item.afterContent = JSON.parse(item.afterContent);
                    for (let key in item.afterContent) {
                        let v = item.afterContent[key];
                        if (v) {
                            item.afterContent[key] = v;
                        }
                    }
                    item.beforeContent = JSON.parse(item.beforeContent);
                    for (let key in item.beforeContent) {
                        let v = item.beforeContent[key];
                        if (v) {
                            item.beforeContent[key] = v;
                        }
                    }
                } else if (item.eventName == "删除" || item.eventName == "新增") {
                    item.actionContent = JSON.parse(item.actionContent);
                    for (let key in item.actionContent) {
                        let v = item.actionContent[key];
                        if (v) {
                            if (key == "修改时间" || key == "创建时间") {
                                v = this.dateTimeFormat(v);
                            }
                            item.actionContent[key] = v;
                        }
                    }
                }
            });
            return list;
        }
        /**
        * 根据dataCode获取系统字典的列表
        */
        Vue.prototype.getDataDictionaryAllList = function (dataCode, listName) {
            return new Promise((success) => {
                this.$http
                    .get(
                        `sys-dict/getByCode?dataCode=${dataCode}`
                    )
                    .then((res) => {
                        let data = res.data;
                        if (data) {
                            data.forEach(item => {
                                if (Number(item.dataValue)) {
                                    item.dataValue = Number(item.dataValue)
                                }
                            });
                            this.$store.state[listName] = data;
                        }
                        success()
                    });
            });
        }

        /**
         * 获取系统所有属于销售经理岗位的员工，并且是审核通过的
         */
        Vue.prototype.getSaleAllList = function () {
            // let postData = {
            //     eq: [
            //         ["String", "approval_status", "2"],
            //         ["String", "role_id", "1563049116651610114"],
            //     ],
            //     orderBy: [["String", "entry_time", "true"]],
            // };
            this.$http
                .get(`employee/getSaleList`)
                .then((res) => {
                    this.$store.state.saleAllList = res.data;
                });
        }
        /**
         * 统一处理审核消息的各种状态
         * @param {消息对象} data 
         * @returns 
         */
        Vue.prototype.processAuditStepsDetailList = function (list) {
            let approvalStatusTxt = ''
            let approvalStatusColor = ''
            let submitterName = ''
            for (let i = 0; i < list.length; i++) {
                let item = list[i]
                if (item.step == 0) submitterName = item.reviewedName
                if (item.auditStatus == 3) {
                    approvalStatusColor = '#FC5858'
                    approvalStatusTxt = '已拒绝'
                    break
                }
                if (item.auditStatus == 4) {
                    item.underApproval = true;
                    approvalStatusColor = '#ee893c'
                    approvalStatusTxt = `等待${item.reviewedName}处理`
                    break
                }
                approvalStatusColor = '#67DB58'
                approvalStatusTxt = '审核通过'
            }
            return { list, approvalStatusTxt, approvalStatusColor, submitterName };
        }
    }
}